import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
// import IGBar from "../components/instagram-bar"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

import formatReadingTime from "reading-time-emoji"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteSocials = data.site.siteMetadata.social
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle} social={siteSocials}>
      <SEO title="Joseph.to - a dev blog" />
      <Bio />
      {/* <IGBar /> */}
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        console.log("node ", node.fields.readingTime.text)
        return (
          <article key={node.fields.slug}>
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>
                {node.frontmatter.date} •{" "}
                {formatReadingTime(
                  Math.round(node.fields.readingTime.minutes),
                  { short: "🍿", long: "🍕" }
                )}
              </small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          twitter
          github
          instagram
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
              minutes
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
